<template>
  <div>
    <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" :filter-data="datatable.filter">
      <template v-slot:document_no_="{ item }">
        <router-link :to="'/purchasing/offer/detail?id=' + item.id">{{ item.document_no_ }}</router-link>
      </template>

      <template v-slot:vendor_name="{ item }">
        <app-vendor-info :vendor-id="item.vendor_id">{{ item.vendor_name }}</app-vendor-info>
      </template>

      <template v-slot:status="{ item }">
        <div class="text-center">
          <app-document-status v-model="item.status"></app-document-status>
        </div>
      </template>

      <template v-slot:created_by_username="{ item }">
        <app-user-info :username="item.created_by_username">{{ item.created_by_username }}</app-user-info>
      </template>
    </app-data-table>
  </div>
</template>

<script>
export default {
  props: {
    tender_header_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: null,

      datatable: {
        uri: "tender/offer/data",
        headers: [
          { text: "Document No.", value: "document_no_", class: "text-no-wrap" },
          { text: "Document Date", value: "document_date", class: "text-no-wrap" },
          { text: "Vendor Name", value: "vendor_name", class: "text-no-wrap" },
          { text: "Phone No.", value: "vendor_phone_no", class: "text-no-wrap" },
          { text: "PIC", value: "created_by_username", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap text-center", cellClass: "text-center" },
        ],
        filter: {
          tender_header_id: this.tender_header_id
        }
      },
    };
  },
  watch: {
    tender_header_id(val) {
      this.datatable.filter.tender_header_id = val;
    }
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
  },
  mounted() {
    this.refreshData();
  }
};
</script>
