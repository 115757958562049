<template>
  <card-expansion title="Document Info" mdi-icon="mdi-file" class="mb-3" :isMinimize="false" no-padding>
    <v-simple-table dense fixed-header>
      <template v-slot:default>
        <tbody>
          <tr>
            <th>ID:</th>
            <td>{{ internalValue.id }}</td>
          </tr>
          <tr>
            <th>Document Type:</th>
            <td>{{ internalValue.document_type }}</td>
          </tr>
          <tr>
            <th>Source Document:</th>
            <td>{{ internalValue.source_document }}</td>
          </tr>
          <tr>
            <th>Created At:</th>
            <td>{{ internalValue.created_date }}</td>
          </tr>
          <tr>
            <th>Created By:</th>
            <td>
              <user-info :username="internalValue.created_by_username">{{ internalValue.created_by_username }}</user-info>
            </td>
          </tr>
          <tr>
            <th>Last Updated:</th>
            <td>
              <app-log-data-dialog module_name="tender" :id="internalValue.id">
                <a href="javascript:;">{{ internalValue.last_updated }}</a>
              </app-log-data-dialog>
            </td>
          </tr>
          <tr>
            <th>Status:</th>
            <td><app-document-status v-model="internalValue.status"></app-document-status></td>
          </tr>
          <tr>
            <th>Published:</th>
            <td>
              <v-icon v-if="internalValue.published" color="green">mdi-check-circle</v-icon>
              <v-icon v-else color="error">mdi-close-circle</v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </card-expansion>
</template>

<script>
import UserInfo from '@/components/user/UserInfo.vue';
export default {
  props: ["value"],
  components: {
    UserInfo,
  },
  data() {
    return {
      internalValue: this.value
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.internalValue = val
      }
    }
  }
}
</script>